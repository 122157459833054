import * as React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import "./Contact.css";


function ContactUs() {
    return (
        <>
            <Navbar />
            <section className="contact-us-master">
          <h2 className="section-title-product">Contact Us</h2>
          <p className="section-description-product">
            {" "}
            Ready to take control of your energy future? Contact Matoto Energy
            Group today to learn more about our solar solutions and how we can
            help you harness the power of the sun.{" "}
          </p>
          <form className="contact-form">
            <legend className="form-title">Send a Query</legend>
            <input
              className="form-input"
              type="text"
              id="nameInput"
              placeholder="Your Name"
              aria-label="Your Name"
            />
            <input
              className="form-input"
              type="email"
              id="emailInput"
              placeholder="Your Email"
              aria-label="Your Email"
            />
            <textarea
              className="form-textarea"
              id="messageInput"
              placeholder="Your Message"
              aria-label="Your Message"
            ></textarea>
            <div className="contact-subscribe">
            <input className="checkbox" type="checkbox"></input>
            <p className="subscribe-text">Subscribe Our Mailing List</p>
          </div>
            <button className="form-button">Submit</button>
          </form>

        </section>
            <Footer />
        </>
    );
}
export default ContactUs;