import * as React from "react";
import "./Blogs.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
// import blog_image_1 from "../../assets/image/blog_image_1.svg";
import blog_image_1 from "../../assets/optimized_images/blog_image_1.png";
// import blog_image_2 from "../../assets/image/blog_image_2.svg";
import blog_image_2 from "../../assets/optimized_images/blog_image_2.png";
// import blog_image_4 from "../../assets/image/blog_image_4.svg";
import blog_image_4 from "../../assets/optimized_images/blog_image_4.png";


function Blogs() {
    return(
        <>
        <Navbar />
        <div className="main-blogs">
            <section className="contact-blog">
                <h2 className="section-title-product-blog">Stay Updated with Solar Energy Insights</h2>
                <p className="section-description-product-blog">
                    {" "}
                    Explore our informative articles, case studies, and success stories showcasing the 
                    benefits and potential of solar energy solutions. 
                    Stay updated and informed about the latest trends and advancements in 
                    the renewable energy industry.{" "}
                </p>
            </section>
            <div className="blogs-column">
            <div className="updates-container">
                <article className="update-blog">
                    <img
                    loading="lazy"
                    // src="https://cdn.builder.io/api/v1/image/assets/TEMP/eab08ffa88940e9e6aefaa5add55a91f73e08572f6c33a8f206058a6851a7b73?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                    src={blog_image_1}
                    alt="solar power advantages"
                    className="update-img"
                    />
                    <div className="update-content">
                    <div className="date-time">
                    {/* <div className="update-date">09/05/2024</div>
                    <div className="update-time"> 10:00 Am-3:00 PM</div> */}
                    </div>

                    <h3 className="update-title-blog">
                        Discover the Advantages of Solar Power
                    </h3>
                    <div className="update-button-master">
                        <button className="update-button" onClick={() => window.location.href = '/blogpage1'}>Read More</button>
                    </div>
                    </div>
                </article>
                <article className="update-blog">
                    <img
                    loading="lazy"
                    // src="https://cdn.builder.io/api/v1/image/assets/TEMP/dbf0dc962fa8e85061aa1aff118f52c79e9c0e45b5aa2306a1435df5deec0254?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                    src={blog_image_2}
                    alt="latest solar technology"
                    className="update-img"
                    />
                    <div className="update-content">
                    <div className="date-time">
                    {/* <div className="update-date">09/05/2024</div>
                    <div className="update-time"> 10:00 Am-3:00 PM</div> */}
                    </div>
                    <h3 className="update-title-blog">
                        Exploring the Latest in Solar Technology
                    </h3>
                    <div className="update-button-master">
                        <button className="update-button" onClick={() => window.location.href = '/blogpage'}>Read More</button>
                    </div>
                    </div>
                </article>
                <article className="update-blog">
                    <img
                    loading="lazy"
                    // src="https://cdn.builder.io/api/v1/image/assets/TEMP/f1e78fe069f2a3fb9445ef515109ca3c0d759d313ff7ba01d5273f40b611e394?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                    src={blog_image_4}
                    alt="solar efficiency tips"
                    className="update-img"
                    />
                    <div className="update-content">
                    <div className="date-time">
                    {/* <div className="update-date">09/05/2024</div>
                    <div className="update-time"> 10:00 Am-3:00 PM</div> */}
                    </div>
                    <h3 className="update-title-blog">
                        Practical Tips for Maximizing Solar Efficiency
                    </h3>
                    <div className="update-button-master">
                        <button className="update-button" onClick={() => window.location.href = '/blogpage2'}>Read More</button>
                    </div>
                    </div>
                </article>
            </div>
            {/* <div className="updates-container">
                <article className="update-blog">
                    <img
                    loading="lazy"
                    // src="https://cdn.builder.io/api/v1/image/assets/TEMP/eab08ffa88940e9e6aefaa5add55a91f73e08572f6c33a8f206058a6851a7b73?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                    src={blog_image_1}
                    alt="solar power advantages"
                    className="update-img"
                    />
                    <div className="update-content">
                    <div className="date-time">
                    <div className="update-date">09/05/2024</div>
                    <div className="update-time"> 10:00 Am-3:00 PM</div>
                    </div>

                    <h3 className="update-title-blog">
                        Discover the Advantages of Solar Power
                    </h3>
                    <div className="update-button-master">
                        <button className="update-button" onClick={() => window.location.href = '/blogpage'}>Read More</button>
                    </div>
                    </div>
                </article>
                <article className="update-blog">
                    <img
                    loading="lazy"
                    // src="https://cdn.builder.io/api/v1/image/assets/TEMP/dbf0dc962fa8e85061aa1aff118f52c79e9c0e45b5aa2306a1435df5deec0254?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                    src={blog_image_2}
                    alt="latest solar technology"
                    className="update-img"
                    />
                    <div className="update-content">
                    <div className="date-time">
                    <div className="update-date">09/05/2024</div>
                    <div className="update-time"> 10:00 Am-3:00 PM</div>
                    </div>
                    <h3 className="update-title-blog">
                        Exploring the Latest in Solar Technology
                    </h3>
                    <div className="update-button-master">
                        <button className="update-button" onClick={() => window.location.href = '/blogpage'}>Read More</button>
                    </div>
                    </div>
                </article>
                <article className="update-blog">
                    <img
                    loading="lazy"
                    // src="https://cdn.builder.io/api/v1/image/assets/TEMP/f1e78fe069f2a3fb9445ef515109ca3c0d759d313ff7ba01d5273f40b611e394?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                    src={blog_image_4}
                    alt="solar efficiency tips"
                    className="update-img"
                    />
                    <div className="update-content">
                    <div className="date-time">
                    <div className="update-date">09/05/2024</div>
                    <div className="update-time"> 10:00 Am-3:00 PM</div>
                    </div>
                    <h3 className="update-title-blog">
                        Practical Tips for Maximizing Solar Efficiency
                    </h3>
                    <div className="update-button-master">
                        <button className="update-button" onClick={() => window.location.href = '/blogpage'}>Read More</button>
                    </div>
                    </div>
                </article>
            </div> */}
            {/* <div className="updates-container">
                <article className="update-blog">
                    <img
                    loading="lazy"
                    // src="https://cdn.builder.io/api/v1/image/assets/TEMP/eab08ffa88940e9e6aefaa5add55a91f73e08572f6c33a8f206058a6851a7b73?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                    src={blog_image_1}
                    alt="solar power advantages"
                    className="update-img"
                    />
                    <div className="update-content">
                    <div className="date-time">
                    <div className="update-date">09/05/2024</div>
                    <div className="update-time"> 10:00 Am-3:00 PM</div>
                    </div>

                    <h3 className="update-title-blog">
                        Discover the Advantages of Solar Power
                    </h3>
                    <div className="update-button-master">
                        <button className="update-button" onClick={() => window.location.href = '/blogpage'}>Read More</button>
                    </div>
                    </div>
                </article>
                <article className="update-blog">
                    <img
                    loading="lazy"
                    // src="https://cdn.builder.io/api/v1/image/assets/TEMP/dbf0dc962fa8e85061aa1aff118f52c79e9c0e45b5aa2306a1435df5deec0254?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                    src={blog_image_2}
                    alt="latest solar technology"
                    className="update-img"
                    />
                    <div className="update-content">
                    <div className="date-time">
                    <div className="update-date">09/05/2024</div>
                    <div className="update-time"> 10:00 Am-3:00 PM</div>
                    </div>
                    <h3 className="update-title-blog">
                        Exploring the Latest in Solar Technology
                    </h3>
                    <div className="update-button-master">
                        <button className="update-button" onClick={() => window.location.href = '/blogpage'}>Read More</button>
                    </div>
                    </div>
                </article>
                <article className="update-blog">
                    <img
                    loading="lazy"
                    // src="https://cdn.builder.io/api/v1/image/assets/TEMP/f1e78fe069f2a3fb9445ef515109ca3c0d759d313ff7ba01d5273f40b611e394?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                    src={blog_image_4}
                    alt="solar efficiency tips"
                    className="update-img"
                    />
                    <div className="update-content">
                    <div className="date-time">
                    <div className="update-date">09/05/2024</div>
                    <div className="update-time"> 10:00 Am-3:00 PM</div>
                    </div>
                    <h3 className="update-title-blog">
                        Practical Tips for Maximizing Solar Efficiency
                    </h3>
                    <div className="update-button-master">
                        <button className="update-button" onClick={() => window.location.href = '/blogpage'}>Read More</button>
                    </div>
                    </div>
                </article>
            </div> */}
            </div>
        </div>
        <Footer />
        </>
    );
}

export default Blogs;