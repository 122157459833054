import * as React from "react";
import "./SingleBlog.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
// import image1 from "../../assets/image/blogpage_image_1.svg";
import image1 from "../../assets/optimized_images/blogpage_image_1.png";
import image2 from "../../assets/image/blogpage_image_2.svg";
import image3 from "../../assets/image/blogpage_image_3.svg";


function Singleblog() {
  return (
    <>
      <Navbar />
      <div className="div">
        <div className="div-internal">
        <div className="div-2">
          <div className="div-3">
            <div className="column">
              <img loading="lazy" srcSet={image1} className="img" alt="image1"/>
            </div>
            <div className="column-2">
              <div className="div-4">
                <img loading="lazy" srcSet={image2} className="img-2" alt="image2"/>
                <img loading="lazy" srcSet={image3} className="img-3" alt="image3"/>
              </div>
            </div>
          </div>
        </div>
        <div className="div-5">
          {/* <div className="div-6">09/05/2024</div>
          <div className="div-7">10:00 Am-3:00 PM</div> */}
        </div>
        <div className="div-8">
          Exploring the Latest in{" "}
          <span style={{ color: "rgba(57,181,74,1)" }}>Solar </span>Technology
        </div>
        <div className="div-9">
          Welcome to Matoto Energy Group's blog, your go-to resource for all
          things related to solar energy and sustainable living. Our mission is
          to empower individuals, businesses, and communities with knowledge and
          insights to make informed decisions about renewable energy solutions.
          <br />
          In our blog, you'll find a wealth of information covering a wide range
          of topics. We delve into the environmental benefits of solar power,
          discussing how it reduces carbon emissions, mitigates climate change,
          and contributes to a cleaner planet. Our articles also explore the
          economic advantages of solar energy, including cost savings on
          electricity bills, incentives for renewable energy adoption, and the
          potential for generating income through solar power.
        </div>
        <div className="div-10">
          Exploring the Environmental Benefits of{" "}
          <span style={{ color: "rgba(57,181,74,1)" }}>Solar </span>
          Power
        </div>
        <div className="div-11">
          We take pride in sharing practical tips and strategies for maximizing
          the efficiency of solar energy systems. Whether you're interested in
          optimizing your current setup or planning a new installation, our blog
          provides valuable guidance on system design, maintenance best
          practices, and energy-saving techniques.
          <br />
          Additionally, we feature real-life case studies and success stories
          that highlight the positive impact of solar energy in diverse
          settings. From residential homes to commercial enterprises and
          industrial facilities, these stories showcase how solar power
          transforms lives, enhances sustainability, and contributes to a
          brighter future.
          <br />
          At Matoto Energy Group, we believe that education is key to driving
          the adoption of renewable energy. Our blog serves as a platform for
          learning, discussion, and inspiration. Join us on this journey towards
          a cleaner, greener world powered by the sun..
          <br />
          Thank you for visiting our blog, and we invite you to explore the
          wealth of information we have to offer. Together, let's harness the
          power of solar energy for a sustainable tomorrow.
        </div>
        {/* <div className="div-12">Read Now</div> */}
      </div>
      </div>
      <Footer />
    </>
  );
}
export default Singleblog;
