import * as React from "react";
// import "./SingleBlog.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import image1 from "../../assets/image/second_blog_1.jpg";
import image2 from "../../assets/image/second_blog_2.jpg";
import image3 from "../../assets/image/second_blog_3.jpg";


function SecondBlog() {
  return (
    <>
      <Navbar />
      <div className="div">
        <div className="div-internal">
        <div className="div-2">
          <div className="div-3">
            <div className="column">
              <img style={{borderRadius: "15px"}} loading="lazy" srcSet={image1} className="img" alt="image1"/>
            </div>
            <div className="column-2">
              <div className="div-4">
                <img style={{borderRadius: "15px", objectFit: "cover"}} loading="lazy" srcSet={image2} className="img-2" alt="image2"/>
                <img style={{borderRadius: "15px", objectFit: "cover"}} loading="lazy" srcSet={image3} className="img-3" alt="image3"/>
              </div>
            </div>
          </div>
        </div>
        <div className="div-5">
          {/* <div className="div-6">09/05/2024</div>
          <div className="div-7">10:00 Am-3:00 PM</div> */}
        </div>
        <div className="div-8">
        Discover the Advantages of Solar Power
        {/* {" "}
          <span style={{ color: "rgba(57,181,74,1)" }}>Solar </span>Technology */}
        </div>
        <div className="div-9">
            Solar power is revolutionizing the way we think about energy, offering a multitude of benefits that extend beyond 
            environmental impact. By harnessing the sun's energy, solar systems significantly reduce carbon emissions, helping 
            to mitigate climate change and promote cleaner air.
          <br />
            Economically, solar power provides considerable savings on 
            electricity bills and offers various incentives for adoption. Additionally, the potential for 
            generating income through solar installations makes it an attractive option for homeowners and businesses alike.
        </div>
        <div className="div-10">
        The Economic and Environmental Impact of Solar Power
        {/* {" "}
          <span style={{ color: "rgba(57,181,74,1)" }}>Solar </span>
          Power */}
        </div>
        <div className="div-11">
        Adopting solar power isn't just an environmentally conscious choice; it's a smart financial decision too. 
        Solar energy systems can drastically cut energy costs, providing a sustainable solution to rising utility prices.
          <br />
          Government incentives and tax credits further enhance the financial appeal of solar investments. 
          Beyond cost savings, solar power plays a crucial role in reducing our carbon footprint, contributing to 
          global efforts to combat climate change and fostering a healthier, more sustainable planet for future generations.
          {/* <br />
          At Matoto Energy Group, we believe that education is key to driving
          the adoption of renewable energy. Our blog serves as a platform for
          learning, discussion, and inspiration. Join us on this journey towards
          a cleaner, greener world powered by the sun..
          <br />
          Thank you for visiting our blog, and we invite you to explore the
          wealth of information we have to offer. Together, let's harness the
          power of solar energy for a sustainable tomorrow. */}
        </div>
        {/* <div className="div-12">Read Now</div> */}
      </div>
      </div>
      <Footer />
    </>
  );
}
export default SecondBlog;
