import { useState } from 'react';
import * as React from "react";
import "./HomePage.css";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
// import homepage_image1 from "../../assets/image/homepage_image1.svg";
import homepage_image1 from "../../assets/optimized_images/homepage_image1.png";
// import homepage_image2 from "../../assets/image/homepage_image2.svg";
import homepage_image2 from "../../assets/optimized_images/homepage_image2.png";
// import homepage_image3 from "../../assets/image/homepage_image3.svg";
import homepage_image3 from "../../assets/optimized_images/homepage_image3.png";
// import homepage_image4 from "../../assets/image/homepage_image4.svg";
import homepage_image4 from "../../assets/optimized_images/homepage_image4.png";
// import homepage_image5 from "../../assets/image/homepage_image5.svg";
import homepage_image5 from "../../assets/optimized_images/homepage_image5.png";
// import homepage_image6 from "../../assets/image/homepage_image6.svg";
import homepage_image6 from "../../assets/optimized_images/homepage_image6.png";
// import homepage_image7 from "../../assets/image/homepage_image7.svg";
import homepage_image7 from "../../assets/optimized_images/homepage_image7.png";
// import update_homepage_image1 from "../../assets/image/update_homepage_image1.svg";
import update_homepage_image1 from "../../assets/optimized_images/update_homepage_image1.png";
import black_image1 from "../../assets/image/black_image1.png";
import black_image2 from "../../assets/image/black_image2.png";
import black_image3 from "../../assets/image/black_image3.png";
import black_image4 from "../../assets/image/black_image4.png";
import black_image5 from "../../assets/image/black_image5.png";
import black_image6 from "../../assets/image/black_image6.png";


import emailjs from 'emailjs-com';


function HomePage() {
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [subscribe, setSubscribe] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);


  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const templateParams = {
      from_name: name,
      from_email: email,
      message: message,
      subscribe: subscribe ? 'Yes' : 'No',
      to_email: 'info@matoto.energy',
    };

    emailjs.send(
        // 'your_service_id',
        "service_i76iexh",
        // 'your_template_id',
        "template_9f3y1bn",
        templateParams,
        "lb7lLUUaXVhmcEIYf" // Replace with your EmailJS user ID
    )
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      alert('Your message has been sent successfully!');

      // Reload the page after submission
      window.location.reload();

    }, (error) => {
      console.error('FAILED...', error);
      alert('Failed to send message. Please try again.');
    });

    
  };


  return (
    <>
      <Navbar />
      <div className="homepage-master">
        <section className="intro">
          <div className="intro-container">
            <ul className="intro-list">
              <li className="intro-item">
                Reduce your energy bills by generating your own power
              </li>
              <li className="intro-item">
                Stay powered up 24/7, even during load shedding
              </li>
              <li className="intro-item">
                Sell excess energy back to the grid and earn credits
              </li>
              <li className="intro-item">
                Enjoy low monthly fees and flexible contracts!
              </li>
              <li className="intro-item">
                Rely on 24/7 support for peace of mind
              </li>
            </ul>
            {/* <button className="intro-login-button">Log In</button> */}
          </div>
          <div className="intro-text">
            <div className="intro-title">Matoto Energy Group</div>
            <div className="intro-subtitle">
              {" "}
              Meet Matoto Energy Group - Your Key to Uninterrupted Power!
            </div>
          </div>
        </section>
        <section className="portfolio">
          <div className="portfolio-container">
            <div className="porfolio-first-row">
              <figure className="portfolio-figure">
                <img
                  loading="lazy"
                  // src="https://cdn.builder.io/api/v1/image/assets/TEMP/db99b50b50daeb75b8d61099a15e52b9ed8244e734ced1e4d46f8629e46ce681?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                  src={homepage_image1}
                  alt="completed projects"
                  className="portfolio-img"
                />
                <div className="portfolio-stats-padding">
                  <figcaption className="portfolio-stats">
                    <div className="portfolio-stat">
                      <span className="stat-number">200+</span>
                      <span className="stat-label">Projects</span>
                    </div>
                    <div className="portfolio-stat">
                      <span className="stat-number">500M+</span>
                      <span className="stat-label">Kwh Saving Energy</span>
                    </div>
                    <div className="portfolio-stat">
                      <span className="stat-number">200M+</span>
                      <span className="stat-label">CO2 is Avoided</span>
                    </div>
                  </figcaption>
                </div>
              </figure>

              <figure className="portfolio-figure">
                <img
                  loading="lazy"
                  // src="https://cdn.builder.io/api/v1/image/assets/TEMP/93cda770dcb71d9d23ef3acfe2bd2c3df223b5b292ab150e66202f1bb1ec59a7?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                  src={homepage_image2}
                  alt="ongoing projects"
                  className="portfolio-img"
                />
              </figure>
            </div>
            <div className="porfolio-second-row">
              <figure className="portfolio-figure">
                <img
                  loading="lazy"
                  // src="https://cdn.builder.io/api/v1/image/assets/TEMP/93cda770dcb71d9d23ef3acfe2bd2c3df223b5b292ab150e66202f1bb1ec59a7?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                  src={homepage_image3}
                  alt="ongoing projects"
                  className="portfolio-img-second-row"
                />
              </figure>
              <figure className="portfolio-figure">
                <img
                  loading="lazy"
                  // src="https://cdn.builder.io/api/v1/image/assets/TEMP/93cda770dcb71d9d23ef3acfe2bd2c3df223b5b292ab150e66202f1bb1ec59a7?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                  src={homepage_image4}
                  alt="ongoing projects"
                  className="portfolio-img-second-row"
                />
              </figure>
            </div>
          </div>
        </section>

        <section className="services">
        <div className="services-cotainer-grid">
        <div className="services-container">
          <h2 className="section-title">Our Services:</h2>
            <article className="service">
              <figure className="service-figure">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/47a19f889740e6e7a23c99c2a4f39adb31842da7be6b76f8ed82dc2c0a88db8a?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                  alt="residential solutions"
                  className="service-img"
                />
                <figcaption className="service-caption">
                  Residential Solutions:
                </figcaption>
              </figure>
              <p className="service-description">
                {" "}
                Experience reliable backup power for your home, reduce energy
                bills, and stay powered up 24/7, even during load shedding.{" "}
              </p>
            </article>
            <article className="service">
              <figure className="service-figure">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/c8130d2687c1b82bb92f76448fbdbd0f0934372a513a12339c784e2a9c1f9736?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                  alt="commercial solutions"
                  className="service-img"
                />
                <div className="service-caption">
                  Commercial Solutions:
                </div>
              </figure>
              <p className="service-description">
                {" "}
                Tailored solar solutions for businesses to reduce operating
                costs and contribute to sustainability goals.{" "}
              </p>
            </article>
            <article className="service">
              <figure className="service-figure">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/9f0c65a0cc0a3f2d8875ce80c32b7d5ed2551e04df2430ac2e52611dc4d72dfa?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                  alt="industrial solutions"
                  className="service-img"
                />
                <figcaption className="service-caption">
                  Industrial Solutions:
                </figcaption>
              </figure>
              <p className="service-description">
                {" "}
                Scalable solar energy solutions for industrial applications,
                ensuring efficiency and cost-effectiveness.{" "}
              </p>
            </article>
          </div>


          <div className="services-container-right">
          <p className="section-description-right">
              {" "}
              Explore our range of services tailored to meet your solar energy
              needs. From professional installation and maintenance to customized
              design and grid integration, we provide comprehensive solutions for
              residential, commercial, and industrial clients.{" "}
            </p>
            <article className="service-right">
              <figure className="service-figure-right">
                <img
                  loading="lazy"
                  // src="https://cdn.builder.io/api/v1/image/assets/TEMP/8339b7501d6f4f4d7b68a1ad5fdde619411cd975cf8da7fb7370ca168c5b8bee?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                  src={homepage_image5}
                  alt="energy audit"
                  className="service-img-right"
                />
              </figure>
              <p className="service-description-right">
                {" "}
                Gain insights into your energy consumption and maximize
                efficiency with our comprehensive energy audits and optimization
                services. Our expert team will assess your current energy usage,
                identify areas for improvement, and provide tailored
                recommendations to help you save costs and enhance
                sustainability.{" "}
              </p>
            </article>
            </div>
            </div>
        </section>

        <section className="promise">
          <div className="promise-flex-column">
          <div className="section-title-promise">Our Promise:</div>
          <p className="section-description-promise">
            {" "}
            There are several reasons to clean your solar panels, but the most
            important is the payback you receive from maintaining the highest
            efficiency possible by keeping PV modules free of dirt & debris.{" "}
          </p>
        </div>
          <div className="promise-container">
            <article className="promise-item">
              <figure className="promise-figure">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/8f7707633ed74dcef5d777a550221c31c2b61f76a90f900277188eabbdf947fd?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                  alt="quality assurance"
                  className="promise-img"
                />
                <figcaption className="promise-caption">
                  Quality Assurance
                </figcaption>
              </figure>
              <p className="promise-description">
                {" "}
                We source products from reputable manufacturers and conduct
                rigorous quality checks to ensure reliability and longevity.{" "}
              </p>
            </article>
            <div className="promise-border-vertical"></div>
            <article className="promise-item">
              <figure className="promise-figure">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/9e58bcd3104ab71fe316f03afdc7a8ed69b775d55afc825d5f3b70a83e9a2673?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                  alt="customized solutions"
                  className="promise-img"
                />
                <figcaption className="promise-caption">
                  Customized Solutions
                </figcaption>
              </figure>
              <p className="promise-description">
                {" "}
                Tailoring solar solutions to meet your specific needs and
                budget, we offer flexibility and personalized service.{" "}
              </p>
            </article>
            <div className="promise-border-vertical"></div>
            <article className="promise-item">
              <figure className="promise-figure">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/ea501ea6a35abefb27375cb948f5969ad84946788a320ead809ccf28a06469db?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                  alt="environmental impact"
                  className="promise-img"
                />
                <figcaption className="promise-caption">
                  Environmental Impact
                </figcaption>
              </figure>
              <p className="promise-description">
                {" "}
                Promoting solar energy reduces emissions, fights climate change,
                and helps the environment.{" "}
              </p>
            </article>
          </div>
        </section>
        <section className="products">
          <h2 className="section-title-product">
            Our Products
            </h2>
          <p className="section-description-product">
            {" "}
            Discover our range of high-efficiency solar panels, inverters,
            batteries, and accessories designed to meet residential, commercial,
            and industrial needs.{" "}
          </p>
          <button className="products-explore-button" 
            onClick={() => window.location.href = '/shop'}
          >
            Explore All
          </button>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/27138331c6a62309fbbd78d11d192e646a823ef41903da6233d856b6dad66c61?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
            alt="shop banner"
            className="products-banner"
          />
          <button className="products-shop-button"
          onClick={() => window.location.href = '/shop'}
          >
            Shop Now</button>
        </section>
        <section className="testimonials">
          <div className="testimonials-column">
          <h2 className="section-title-testimonials">what’s our client says about us</h2>
          <p className="section-description-testimonials">
            {" "}
            There are some testimonials from customers who are satisfied with
            the products and services we have provided{" "}
          </p>
          </div>
          <div className="testimonials-container">
            <article className="testimonial">
              <figure className="testimonial-figure">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/97377a7fbe09eac3b619cb045f889526899b3c5a1b41c6d366bdc42512cd73b9?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                  alt="testimonial"
                  className="testimonial-img"
                />
                <figcaption className="testimonial-content">
                  <p className="testimonial-text">
                    {" "}
                    Matoto Energy Group delivered exceptional service. Our solar
                    solution is top-notch, and we're thrilled with the results!{" "}
                  </p>
                  <div className="testimonial-author">
                    <img
                      loading="lazy"
                      // src="https://cdn.builder.io/api/v1/image/assets/TEMP/2931e6a074b958a469bf543ca2cfc7550194bfa76754e45c127b36a8a3ad8e12?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                      src={black_image1}
                      alt="author"
                      className="author-img"
                    />
                    <div className="author-info">
                      <span className="author-name">Dirk Ramasodi</span>
                      <span className="author-position">Co-founder</span>
                    </div>
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/1fcf330f429535793146e7f75aca20ed5be74b961fb148e38967334af4706244?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                      alt="quote icon"
                      className="quote-icon"
                    />
                  </div>
                </figcaption>
              </figure>
            </article>
            <article className="testimonial">
              <figure className="testimonial-figure">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/c9800cca02403ba9385547880102758249a5012ff32795fe53daded37c7e2dfc?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                  alt="testimonial"
                  className="testimonial-img"
                />
                <figcaption className="testimonial-content">
                  <p className="testimonial-text">
                    {" "}
                    Switching to solar with Matoto Energy Group was a great
                    decision. Our bills are down, and we feel good about our
                    eco-friendly choice!{" "}
                  </p>
                  <div className="testimonial-author">
                    <img
                      loading="lazy"
                      // src="https://cdn.builder.io/api/v1/image/assets/TEMP/0647d959fe87e8c4a7321e8f650588975cb64b30a80decc2c2e1bcf4b29468de?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                      src={black_image2}
                      alt="author"
                      className="author-img"
                    />
                    <div className="author-info">
                      <span className="author-name">Max Rhavhulani</span>
                      <span className="author-position">CEO</span>
                    </div>
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/1fcf330f429535793146e7f75aca20ed5be74b961fb148e38967334af4706244?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                      alt="quote icon"
                      className="quote-icon"
                    />
                  </div>
                </figcaption>
              </figure>
            </article>
            <article className="testimonial">
              <figure className="testimonial-figure">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/c1c9b2ce8889399e71160b2b76c9b8ee735985a406154a1ee4b6dd798b9cb42c?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                  alt="testimonial"
                  className="testimonial-img"
                />
                <figcaption className="testimonial-content">
                  <p className="testimonial-text">
                    {" "}
                    We're happy customers of Matoto Energy Group. Our solar
                    installation was efficient, and we're already experiencing
                    the benefits of reduced energy costs.{" "}
                  </p>
                  <div className="testimonial-author">
                    <img
                      loading="lazy"
                      // src="https://cdn.builder.io/api/v1/image/assets/TEMP/57b901fe757a619b130f1356fd514b0a55d545c0e0d8c5b5e17d80eda2841650?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                      src={black_image3}
                      alt="author"
                      className="author-img"
                    />
                    <div className="author-info">
                      <span className="author-name">Munesh Rakoma</span>
                      <span className="author-position">Co-founder</span>
                    </div>
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/1fcf330f429535793146e7f75aca20ed5be74b961fb148e38967334af4706244?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                      alt="quote icon"
                      className="quote-icon"
                    />
                  </div>
                </figcaption>
              </figure>
            </article>
            <article className="testimonial">
              <figure className="testimonial-figure">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/6cbd062cc31e6e552d8babf558f2913e0a7aef7d9a93968755d68fbc3297036f?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                  alt="testimonial"
                  className="testimonial-img"
                />
                <figcaption className="testimonial-content">
                  <p className="testimonial-text">
                    {" "}
                    Matoto Energy Group delivered top-notch service and
                    products. Our solar system is exceeding expectations, and
                    we're thrilled with the financial savings{" "}
                  </p>
                  <div className="testimonial-author">
                    <img
                      loading="lazy"
                      // src="https://cdn.builder.io/api/v1/image/assets/TEMP/0560c7aad78bc47dad1f10adb1cdd2b1fb80d3aa6fb0fb21feeeeeb4f18a4ea2?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                      src={black_image4}
                      alt="author"
                      className="author-img"
                    />
                    <div className="author-info">
                      <span className="author-name">Patricia Ravhuhali</span>
                      <span className="author-position">Co-founder</span>
                    </div>
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/1fcf330f429535793146e7f75aca20ed5be74b961fb148e38967334af4706244?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                      alt="quote icon"
                      className="quote-icon"
                    />
                  </div>
                </figcaption>
              </figure>
            </article>
            <article className="testimonial">
              <figure className="testimonial-figure">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/26179a6c7999c4634f51f6f5c0bd59d02c9fbde8cb46ad50c2bcd2d5aea796a3?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                  alt="testimonial"
                  className="testimonial-img"
                />
                <figcaption className="testimonial-content">
                  <p className="testimonial-text">
                    {" "}
                    Choosing Matoto Energy Group for our solar needs was a smart
                    decision. The entire process was smooth, and we're enjoying
                    reliable and eco-friendly energy.{" "}
                  </p>
                  <div className="testimonial-author">
                    <img
                      loading="lazy"
                      // src="https://cdn.builder.io/api/v1/image/assets/TEMP/1a7d5be658ca6aca645b45a17d88e588a0f3128d60bf341cdc68680aa526fa74?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                      src={black_image5}
                      alt="author"
                      className="author-img"
                    />
                    <div className="author-info">
                      <span className="author-name">Glen Qwabe</span>
                      <span className="author-position">Co-founder</span>
                    </div>
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/1fcf330f429535793146e7f75aca20ed5be74b961fb148e38967334af4706244?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                      alt="quote icon"
                      className="quote-icon"
                    />
                  </div>
                </figcaption>
              </figure>
            </article>
            <article className="testimonial">
              <figure className="testimonial-figure">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/5c8fc301141862a177abdae7931aaed47888c069441749d60a9a6d19e2f72f04?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                  alt="testimonial"
                  className="testimonial-img"
                />
                <figcaption className="testimonial-content">
                  <p className="testimonial-text">
                    {" "}
                    Matoto Energy Group delivered top-notch service and
                    products. Our solar system is exceeding expectations, and
                    we're thrilled with the financial savings{" "}
                  </p>
                  <div className="testimonial-author">
                    <img
                      loading="lazy"
                      // src="https://cdn.builder.io/api/v1/image/assets/TEMP/a0b9d4c4e269c43ccd4328cd6856b3369459745c2314a968f091b6ca14fca143?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                      src={black_image6}
                      alt="author"
                      className="author-img"
                    />
                    <div className="author-info">
                      <span className="author-name">Smit Kruger</span>
                      <span className="author-position">Co-founder</span>
                    </div>
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/1fcf330f429535793146e7f75aca20ed5be74b961fb148e38967334af4706244?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                      alt="quote icon"
                      className="quote-icon"
                    />
                  </div>
                </figcaption>
              </figure>
            </article>
          </div>
        </section>
        <section className="updates">
        <div className="testimonials-column">
          <h2 className="section-title-testimonials">
            Stay Updated with Solar Energy Insights
            </h2>
          <p className="section-description-testimonials">
            {" "}
            Explore our informative articles, case studies, and success stories
            showcasing the benefits and potential of solar energy solutions.
            Stay updated and informed about the latest trends and advancements
            in the renewable energy industry. <br />{" "}
          </p>
          </div>
          <div className="updates-container">
            <article className="update">
              <img
                loading="lazy"
                // src="https://cdn.builder.io/api/v1/image/assets/TEMP/eab08ffa88940e9e6aefaa5add55a91f73e08572f6c33a8f206058a6851a7b73?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                // src={homepage_image5}
                src={update_homepage_image1}
                alt="solar power advantages"
                className="update-img"
              />
              <div className="update-content">
              <div className="date-time">
                {/* <div className="update-date">09/05/2024</div>
                <div className="update-time"> 10:00 Am-3:00 PM</div> */}
              </div>

                <h3 className="update-title">
                  Discover the Advantages of Solar Power
                </h3>
                <div className="update-button-master">
                <button className="update-button"
                    onClick={() => window.location.href = '/about'}
                  >
                    Read Now</button>
                </div>
              </div>
            </article>
            <article className="update">
              <img
                loading="lazy"
                // src="https://cdn.builder.io/api/v1/image/assets/TEMP/dbf0dc962fa8e85061aa1aff118f52c79e9c0e45b5aa2306a1435df5deec0254?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                src={homepage_image6}
                alt="latest solar technology"
                className="update-img"
              />
              <div className="update-content">
              <div className="date-time">
                {/* <div className="update-date">09/05/2024</div>
                <div className="update-time"> 10:00 Am-3:00 PM</div> */}
              </div>
                <h3 className="update-title">
                  Exploring the Latest in Solar Technology
                </h3>
                <div className="update-button-master">
                  <button className="update-button"
                    onClick={() => window.location.href = '/about'}
                  >
                    Read Now</button>
                </div>
              </div>
            </article>
            <article className="update">
              <img
                loading="lazy"
                // src="https://cdn.builder.io/api/v1/image/assets/TEMP/f1e78fe069f2a3fb9445ef515109ca3c0d759d313ff7ba01d5273f40b611e394?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                src={homepage_image7}
                alt="solar efficiency tips"
                className="update-img"
              />
              <div className="update-content">
              <div className="date-time">
                {/* <div className="update-date">09/05/2024</div>
                <div className="update-time"> 10:00 Am-3:00 PM</div> */}
              </div>
                <h3 className="update-title">
                  Practical Tips for Maximizing Solar Efficiency
                </h3>
                <div className="update-button-master">
                <button className="update-button"
                    onClick={() => window.location.href = '/about'}
                  >
                    Read Now</button>
                </div>
              </div>
            </article>
          </div>
        </section>

        <section className="contact">
          <h2 className="section-title-product">Contact Us</h2>
          <p className="section-description-product">
            {" "}
            Ready to take control of your energy future? Contact Matoto Energy
            Group today to learn more about our solar solutions and how we can
            help you harness the power of the sun.{" "}
          </p>
          {/* <form className="contact-form">
            <legend className="form-title">Send a Query</legend>
            <input
              className="form-input"
              type="text"
              id="nameInput"
              placeholder="Your Name"
              aria-label="Your Name"
            />
            <input
              className="form-input"
              type="email"
              id="emailInput"
              placeholder="Your Email"
              aria-label="Your Email"
            />
            <textarea
              className="form-textarea"
              id="messageInput"
              placeholder="Your Message"
              aria-label="Your Message"
            ></textarea>
            <div className="contact-subscribe">
            <input className="checkbox" type="checkbox"></input>
            <p className="subscribe-text">Subscribe Our Mailing List</p>
          </div>
            <button className="form-button">Submit</button>
          </form> */}
          <form className="contact-form" onSubmit={handleSubmit}>
            <legend className="form-title">Send a Query</legend>
            <input
              className="form-input"
              type="text"
              id="nameInput"
              placeholder="Your Name"
              aria-label="Your Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              className="form-input"
              type="email"
              id="emailInput"
              placeholder="Your Email"
              aria-label="Your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <textarea
              className="form-textarea"
              id="messageInput"
              placeholder="Your Message"
              aria-label="Your Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
            <div className="contact-subscribe">
              <input
                className="checkbox"
                type="checkbox"
                id="subscribe"
                checked={subscribe}
                onChange={(e) => setSubscribe(e.target.checked)}
              />
              <p className="subscribe-text">Subscribe Our Mailing List</p>
            </div>
            {/* <button className="form-button" type="submit">Submit</button> */}
            <button className="form-button" type="submit" disabled={isSubmitting}>Submit</button>

          </form>

        </section>
        <section className="footer-contact-info">
          <div className="contact-info-wrapper">
            <div className="contact-address">
              <h3 className="address-title">
                Get in touch with our team of Solar Panel services today!
              </h3>
              <p className="address-description">
                If you want to learn more about to Solar Panel & skylight, get
                in touch with our expert and get your queries addressed today!
              </p>
              <button className="address-button"
                onClick={() => window.location.href = '/contactus'}
              >
                Talk To An Expert</button>
            </div>
          </div>
        </section>
      </div>

    <Footer />
    
    </>
  );
}

export default HomePage;
