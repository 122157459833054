import * as React from "react";
import "./Footer.css";
import { FaFacebookSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

const ContactInfo = ({ imgSrc, altText, title, subtitle }) => (
  <div className="contact-info">
    <img loading="lazy" src={imgSrc} alt={altText} className="contact-icon" />
    <div className="contact-text">
      <div className="contact-title">{title}</div>
      <div className="contact-subtitle">{subtitle}</div>
    </div>
  </div>
);

const NavigationSection = ({ title, links, isFirst }) => (
    <section className={`navigation-section ${isFirst ? 'navigation-section-first' : ''}`}>
      <div className="navigation-title">{title}</div>
      <div className="navigation-links">
        {links.map((link, index) => (
          <a key={index} href={link.href} className="navigation-link">
            {link.text}
          </a>
        ))}
      </div>
    </section>
  );

function Footer() {
  return (
    <>
      <div className="footer-master-div">
      <footer className="main-footer">
        <div className="logo-section">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/14d2af665f4d45fc343658102b94201878073920e98bd94de2dfbc29b80f0a41?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
            alt=""
            className="main-logo"
          />
          {/* <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/4781bbf7ba839c6fbcfb951070eab6f639c4806837df8b54ef5c3f3f8448f308?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
            alt=""
            className="secondary-logo"
          /> */}
          <div className="footer-social-logo">
            <FaFacebookSquare className="footer-social-icon" 
              onClick={() => window.location.href = "https://www.facebook.com/Matotogroup/"}/>
            <FaLinkedin className="footer-social-icon"
              onClick={() => window.location.href = "https://www.linkedin.com/in/matoto-energy-25744631b"}/>
            <FaSquareXTwitter className="footer-social-icon"
              onClick={() => window.location.href = "https://x.com/matotoenergy"}/>

          </div>
        </div>
        <section className="contact-section">
          <ContactInfo
            imgSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/6c88fcd9791063539ba94cb3571d643566d6520959d4e20d648a16d5e0a08706?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
            alt=""
            title="info@matoto.energy"
            subtitle="Email us directly for a quote!"
          />
          <ContactInfo
            imgSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/0a4f93ed8ddd523f0ee2f0ca07ad6fbb9da39ea3dea83102147570a1d7e4fc3b?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
            alt=""
            title="010 498 6225"
            subtitle="Monday - Friday, 8am - 5pm"
          />
          <ContactInfo
            imgSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/bc188ea3024c15d893c5d96afa41645a2f81f26076e6b7a8b3ae8764cc3783d4?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
            alt=""
            title="Main Office"
            subtitle="55 Richards Drive, Midrand, Gauteng"
          />
        </section>
        <main className="main-content">
          <NavigationSection
            title="Website Navigation"
            links={[
              { text: "Home", href: "/" },
              { text: "About", href: "/about" },
              { text: "Products", href: "/shop" },
              { text: "Blogs", href: "/blogs" },
              { text: "Contact Us", href: "/contactus" },
            ]}
            isFirst={true}
          />
        </main>
      </footer>
      </div>
      <div className="footer-bottom">
          {/* Matoto Energy Group Inc. @2023. All Rights Reserved. */}
          {/* Copyright © 2023 Matoto Group Of Companies | Powered by Matoto Technologies */}
          <p class="text-sm leading-5 nav-item text-white">
          Copyright &copy; 2024 Matoto Energy. Member of{" "}
          <a
            className="copyright_links"
            target="_blank"
            href="https://www.matotogroup.co.za/"
          >
            Matoto Group Of Companies{" "}
          </a>{" "}
          | Powered by{" "}
          <a
            className="copyright_links"
            target="_blank"
            href="https://www.matoto.co.za/"
          >
            Matoto Technologies
          </a>
        </p>
        </div>    
    </>
  );
}

export default Footer;
