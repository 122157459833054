import * as React from "react";
import "./ProductPage2.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
// import product_main_pic from "../../assets/image/product_main_image.svg";
// import related_image_1 from "../../assets/image/related-product-1.svg";
import related_image_1 from "../../assets/optimized_images/related-product-1.png";
import { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
// import productpage_image2 from "../../assets/image/productpage_image2.svg";
import productpage_image2 from "../../assets/optimized_images/productpage_image2.png";
// import productpage_image3 from "../../assets/image/productpage_image3.svg";
import productpage_image3 from "../../assets/optimized_images/productpage_image3.png";
import emailjs from "emailjs-com";
import shop_product_image3 from "../../assets/image/shop_product_image3.png";
import Volta700X_2 from "../../assets/image/Volta700X-2.png";
import Volta700X_3 from "../../assets/image/Volta700X-3.png";
import Volta700X_4 from "../../assets/image/Volta700X-4.png";

const faqData = [
  {
    question: "What is solar energy?",
    answer:
      "Solar energy is renewable energy derived from the sun's radiation. It is harnessed using solar panels to generate electricity or heat for various applications.",
  },
  {
    question: "How does solar energy work?",
    answer:
      "Solar panels capture sunlight and convert it into electricity through photovoltaic cells. This electricity can be used immediately, stored in batteries, or fed back into the power grid.",
  },
  {
    question: "How does solar energy reduce carbon emissions?",
    answer:
      "Solar energy produces clean, renewable electricity without burning fossil fuels. By replacing traditional energy sources, it significantly reduces greenhouse gas emissions and helps combat climate change.",
  },
];

const FAQItem = ({ question, answer, isLast }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className="faq-item-master">
      <div className="faq-item">
        <div className="faq-question" onClick={() => setIsOpen(!isOpen)}>
          <span>{question}</span>
          {isOpen ? (
            <IoIosArrowUp className="toggle-icon" />
          ) : (
            <IoIosArrowDown className="toggle-icon" />
          )}
        </div>
        {isOpen && <div className="faq-answer">{answer}</div>}
        {!isLast && <div className="divider" />}
      </div>
    </div>
  );
};

const StarRating = ({ rating }) => {
  const stars = Array.from({ length: 5 }, (_, index) => (
    <span key={index} className={`star ${index < rating ? "filled" : "empty"}`}>
      ★
    </span>
  ));
  return <div className="star-rating">{stars}</div>;
};

const FeatureItem = ({ icon, text }) => (
  <div className="feature-item">
    <img src={icon} alt="" className="feature-icon" />
    <p className="feature-text">{text}</p>
  </div>
);

const KeyFeature = ({ title, icon, description }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="key-feature">
      <div className="key-feature-header">
        <div className="key-feature-title">{title}</div>
        <div onClick={() => setIsOpen(!isOpen)} style={{ cursor: "pointer" }}>
          {isOpen ? (
            <IoIosArrowUp className="product-arrow" />
          ) : (
            <IoIosArrowDown className="product-arrow" />
          )}
        </div>
      </div>
      {isOpen && <div className="key-feature-description">{description}</div>}
    </div>
  );
};

function ProductPage3() {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [contact, setContact] = React.useState("");
  const [showPopup, setShowPopup] = React.useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      from_name: name,
      from_email: email,
      address: address,
      contact: contact,
      // subscribe: subscribe ? 'Yes' : 'No',
      to_email: "info@matoto.energy",
    };

    emailjs
      .send(
        // 'your_service_id',
        "service_i76iexh",
        // 'your_template_id',
        "template_ntm6lpa",
        templateParams,
        "lb7lLUUaXVhmcEIYf" // Replace with your EmailJS user ID
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          alert("Your message has been sent successfully!");
        },
        (error) => {
          console.error("FAILED...", error);
          alert("Failed to send message. Please try again.");
        }
      );
    setShowPopup(false);
  };

  const [quantity, setQuantity] = useState(1);
  const features = [
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/518b616366a3ef9db00ec9ae6c43bdc7057b5677437ab9f297db1a0c8e99bd55?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&",
      text: "Free Shipping all over in South Africa",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/199e31708d32a5abecd3057c0826adda23c005e2766be26f308fe112e4dc9520?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&",
      text: "2-Year Limited Warranty",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/719838991df1b7a1620b9e2d90d557b8bb1ca0fb6c4f7f4775de3488c9455928?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&",
      text: "Round-the-clock support",
    },
  ];

  const handleQuantityChange = (change) => {
    setQuantity((prevQuantity) => Math.max(1, prevQuantity + change));
  };

  const [activeIndex, setActiveIndex] = React.useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  // const faqs = [
  //     {
  //     question: 'What Is Solar Energy?',
  //     answer: 'Solar Energy is Renewable Energy Derived From The Sun\'s Radiation. It Is Harnessed Using Solar Panels To Generate Electricity Or Heat For Various Applications.',
  //     },
  //     {
  //     question: 'How Does Solar Energy Work?',
  //     answer: 'Solar panels absorb sunlight and convert the energy from the sun into electricity through the photovoltaic effect. The electricity generated can then be used to power homes, businesses, and other applications.',
  //     },
  //     {
  //     question: 'How Does Solar Energy Reduce Carbon Emissions?',
  //     answer: 'Solar energy is a clean, renewable energy source that does not produce any greenhouse gas emissions during its operation. By using solar energy instead of fossil fuels, we can significantly reduce our carbon footprint and mitigate climate change.',
  //     },
  // ];

  const [searchTerm, setSearchTerm] = React.useState("");

  const filteredFAQs = faqData.filter((faq) =>
    faq.question.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const [mainImage, setMainImage] = useState(shop_product_image3);
  const [thumbnails, setThumbnails] = useState([
    // "https://cdn.builder.io/api/v1/image/assets/TEMP/244eb45ed071de7ba9da02f909f671d3e72eea03a28ced38b99266a5ff02be97?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&",
    Volta700X_2,
    Volta700X_3,
    Volta700X_4,
    // "https://cdn.builder.io/api/v1/image/assets/TEMP/28ac3a8672a437b95f5538d8ca7897e9977c842371d01c1f35cf0fe3ea572d17?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&",
    // "https://cdn.builder.io/api/v1/image/assets/TEMP/83a14286a6edca2084f604a37e45730c19ec7c267a50597a4eb2a354df4a5f0a?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
  ]);

  const handleThumbnailClick = (index) => {
    // Swap the main image with the clicked thumbnail image
    const newThumbnails = [...thumbnails];
    const temp = mainImage;
    setMainImage(thumbnails[index]);
    newThumbnails[index] = temp;
    setThumbnails(newThumbnails);
  };

  const keyFeaturesDescription = `
      Key features of solar panels include high efficiency in converting sunlight to
      electricity, durability to withstand various weather conditions, reliability in
      maintaining performance over time, strong warranties for customer assurance, and
      certifications ensuring industry standards for safety and performance.
    `;

  const whyChooseUsDescription = `
      Our solar panels offer superior efficiency, unmatched durability, and excellent 
      value for your investment. We provide industry-leading warranties and our products 
      are certified to meet the highest standards of quality and performance.
    `;

  return (
    <>
      <Navbar />
      <main className="product-page">
        <section className="product-showcase">
          <div className="product-images">
            <img
              src={mainImage}
              alt="Solar Panel main view"
              className="main-image"
            />
            <div className="thumbnail-gallery">
              {thumbnails.map((thumbnail, index) => (
                <img
                  key={index}
                  src={thumbnail}
                  alt={`Solar Panel thumbnail ${index + 1}`}
                  className="thumbnail"
                  onClick={() => handleThumbnailClick(index)}
                />
              ))}
            </div>
          </div>
          <div className="product-details">
            <header className="product-header">
              <div className="product-title">Volta 700X</div>
              {/* <img src=
              "https://cdn.builder.io/api/v1/image/assets/TEMP/7900f11af09e3213c980ac32ab6d55973c5930381c5b995af925ef578df1b44d?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&" alt="" className="product-icon" /> */}
            </header>
            <div className="product-description">
            Whether you want to bring the comforts of home to your outdoor exploring or need reliable 
            backup power for home, the Volta 700X provides 537 Wh of power to run all your essentials seamlessly. 
            It’s versatile, boasts wireless charging and looks pretty cool too! And with your monthly 
            subscription, the lifespan, maintenance and upkeep is our problem, not yours. Ever!
            </div>
            <div className="product-price">Starts from R299</div>
            {/* <div className="product-rating">
              <StarRating className="rating-star" rating={4} />
              <span className="review-count">441 Review</span>
            </div> */}
            {/* <div className="product-highlight">
              Amazing Deal :{" "}
              <span className="highlight-detail">Solar Panel</span> */}
            {/* </div> */}
            {/* <div className="quantity-selector">
              <button
                className="quantity-btn"
                aria-label="Decrease quantity"
                onClick={() => handleQuantityChange(-1)}
              >
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/06843ddd4ae6cd25640bd48abb5788b85794e21ed2d4896bf63702b63b759801?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                  alt="Minus"
                  className="quantity-icon"
                />
              </button>
              <span className="quantity-value">{quantity}</span>
              <button
                className="quantity-btn"
                aria-label="Increase quantity"
                onClick={() => handleQuantityChange(1)}
              >
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/ebdb744ebddd188b28510d1565b853872094aed12d8de49747e3ff71b1a13eee?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                  alt="Plus"
                  className="quantity-icon"
                />
              </button>
            </div> */}
            <div className="action-buttons">
              <div className="shop-now-button">
                <button
                  className="address-button"
                  onClick={() => setShowPopup(true)}
                >
                  Shop Now
                </button>
            </div>
            {showPopup && (
                <div className="popup">
                <form className="contact-form" onSubmit={handleSubmit}>
                    <input
                    type="text"
                    placeholder="Your Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    />
                    <input
                    type="email"
                    placeholder="Your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    />
                    <input
                    type="text"
                    placeholder="Your Address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    />
                    <input
                    type="text"
                    placeholder="Your Contact Number"
                    value={contact}
                    onChange={(e) => setContact(e.target.value)}
                    />
                    <button className="submit-button" type="submit">
                    Submit
                    </button>
                    <div className="close-button-master">
                    <button
                        className="close-button"
                        onClick={() => setShowPopup(false)}
                    >
                        Close
                    </button>
                    </div>
                </form>
                </div>
            )}
            </div>
            <div className="product-features">
            {features.map((feature, index) => (
                <FeatureItem
                key={index}
                icon={feature.icon}
                text={feature.text}
                />
            ))}
            </div>
            <KeyFeature
            title="Key Features"
            icon="https://cdn.builder.io/api/v1/image/assets/TEMP/7d25f23478fb6533af51b3a3e2d7142b7f76762acd42ef34cfe65873bc606001?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
            description={keyFeaturesDescription}
            />
            <hr className="info-divider" />
            <KeyFeature
            title="Why Choose Our Solar Panel?"
            description={whyChooseUsDescription}
            />
            <hr className="info-divider" />
        </div>
        </section>
        <section className="product-info"></section>

        <div className="related-products-master">
        <div className="related-product-title">Our Related Products</div>
        <div className="related-product-title-description">
            Discover our range of high-efficiency solar panels, inverters,
            batteries, and accessories designed to meet residential, commercial,
            and industrial needs.
        </div>
        <div className="products-master">
            <div className="related-product-first">
            <img
                className="related-product-image"
                src={related_image_1}
                alt=""
            />
            <div className="first-product-title-desc">
                <div className="first-product-title">Tailored Solutions</div>
                <div className="first-product-desc">
                Explore installation, maintenance, consultancy, and energy
                audits for residential, commercial, and industrial clients.
                </div>
            </div>
            </div>
            <div className="related-product-first">
            <img
                className="related-product-image"
                src={productpage_image2}
                alt=""
            />
            <div className="first-product-title-desc">
                <div className="first-product-title">Tailored Solutions</div>
                <div className="first-product-desc">
                Explore installation, maintenance, consultancy, and energy
                audits for residential, commercial, and industrial clients.
                </div>
            </div>
            </div>
            <div className="related-product-first">
            <img
                className="related-product-image"
                src={productpage_image3}
                alt=""
            />
            <div className="first-product-title-desc">
                <div className="first-product-title">Tailored Solutions</div>
                <div className="first-product-desc">
                Explore installation, maintenance, consultancy, and energy
                audits for residential, commercial, and industrial clients.
            </div>
            </div>
            </div>
        </div>
        </div>
    </main>

    <div className="faq-section">
        <h1 className="section-title">Frequently Asked Questions</h1>
        <p className="section-subtitle">Have Questions? We're Here to help.</p>
        {/* <div className="search-container">
            <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/7a9c92be33e335cba3e237970b49b67fe16c46841e36fe67a4f16d13c97741d7?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&" className="search-icon" alt="" />
            <input
            type="text"
            className="search-input"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div> */}
        {filteredFAQs.map((faq, index) => (
        <FAQItem
            key={index}
            question={faq.question}
            answer={faq.answer}
            isLast={index === faqData.length - 1}
          />
        ))}
      </div>

      <section className="footer-contact-info">
        <div className="contact-info-wrapper">
          <div className="contact-address">
            <h3 className="address-title">
              Get in touch with our team of Solar Panel services today!
            </h3>
            <p className="address-description">
              If you want to learn more about to Solar Panel & skylight, get in
              touch with our expert and get your queries addressed today!
            </p>
            <button className="address-button"
                onClick={() => window.location.href = '/contactus'}
              >
                Talk To An Expert</button>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ProductPage3;
