import * as React from "react";
// import "./SingleBlog.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import image1 from "../../assets/image/third_blog_1.jpg";
import image2 from "../../assets/image/third_blog_2.jpg";
import image3 from "../../assets/image/third_blog_3.jpg";


function ThirdBlog() {
  return (
    <>
      <Navbar />
      <div className="div">
        <div className="div-internal">
        <div className="div-2">
          <div className="div-3">
            <div className="column">
              <img style={{borderRadius: "15px", objectFit: "cover"}} loading="lazy" srcSet={image1} className="img" alt="image1"/>
            </div>
            <div className="column-2">
              <div className="div-4">
                <img style={{borderRadius: "15px", objectFit: "cover"}} loading="lazy" srcSet={image2} className="img-2" alt="image2"/>
                <img style={{borderRadius: "15px", objectFit: "cover"}} loading="lazy" srcSet={image3} className="img-3" alt="image3"/>
              </div>
            </div>
          </div>
        </div>
        <div className="div-5">
          {/* <div className="div-6">09/05/2024</div>
          <div className="div-7">10:00 Am-3:00 PM</div> */}
        </div>
        <div className="div-8">
        Practical Tips for Maximizing Solar Efficiency
        {/* {" "}
          <span style={{ color: "rgba(57,181,74,1)" }}>Solar </span>Technology */}
        </div>
        <div className="div-9">
            Achieving optimal efficiency with your solar energy system involves strategic planning and proactive maintenance. 
            Start by ensuring your solar panels are installed in a location that receives maximum sunlight throughout the day.
          <br />
            Regular cleaning of the panels is essential to remove dust and debris that can obstruct sunlight absorption. 
            Additionally, investing in high-efficiency solar panels and inverters can significantly enhance energy production, 
            ensuring you get the most out of your system.
        </div>
        <div className="div-10">
        Strategic Maintenance and Energy-Saving Practices
        {/* {" "}
          <span style={{ color: "rgba(57,181,74,1)" }}>Solar </span>
          Power */}
        </div>
        <div className="div-11">
            Monitoring your system's performance regularly and adjusting settings accordingly can further optimize efficiency. 
            Consider integrating energy-saving practices such as using energy-efficient appliances and aligning your energy 
            consumption with peak sunlight hours.
          <br />
            Implementing these strategies not only maximizes the output of your solar investment but also extends its longevity. 
            By following these practical tips, you can enjoy greater energy savings and environmental benefits, making your solar 
            energy system a worthwhile and sustainable investment.
          {/* <br />
          At Matoto Energy Group, we believe that education is key to driving
          the adoption of renewable energy. Our blog serves as a platform for
          learning, discussion, and inspiration. Join us on this journey towards
          a cleaner, greener world powered by the sun..
          <br />
          Thank you for visiting our blog, and we invite you to explore the
          wealth of information we have to offer. Together, let's harness the
          power of solar energy for a sustainable tomorrow. */}
        </div>
        {/* <div className="div-12">Read Now</div> */}
      </div>
      </div>
      <Footer />
    </>
  );
}
export default ThirdBlog;
