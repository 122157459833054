import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
    colors: {
        brand: {
            50: '#f9fafb',
            // Add more shades as needed
        },
        // Define other custom colors here
    },
    fonts: {
        body: 'Roboto, sans-serif',
        heading: 'Roboto, sans-serif',
        // Define other fonts here
    },
    components: {
        Button: {
            // Change default props or styles for Button component
            baseStyle: {
                fontWeight: 'bold',
            },
        },
        // Define styles for other components as needed
    },
});

export default theme;
