import './App.css';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './components/theme';
import HomePage from './components/LandingPage/HomePage';
// import LoginPage from './components/LoginPage/LoginPage';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import SignUp from './components/SignUp.js/SignUp';
// import ResetPassword from './components/ResetPassword/ResetPassword';
// import ForgetPassword from './components/ForgetPassword/ForgetPassword';
// import HomePage2 from './components/LandingPage2/HomePage2';
import About from './components/About/About';
import ProductPage2 from './components/ProductsPage/ProductPage2';
import Blogs from './components/Blogs/Blogs';
import Singleblog from './components/SingleBlog/SingleBlog';
import ContactUs from './components/Contact/Contact';
import SecondBlog from './components/SecondBlog/SecondBlog';
import ThirdBlog from './components/ThirdBlog/ThirdBlog';
import Shopnow from './components/Shopnow/Shopnow';
import ProductPage1 from './components/ProductsPage/ProductPage1';
import ProductPage3 from './components/ProductsPage/ProductPage3';


function App() {
  return (
    <ChakraProvider theme={theme}>
    <div className="App">
      <Router> {/* Wrap Routes with Router */}
        <Routes>
          {/* <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/resetpass" element={<ResetPassword />} />
          <Route path="/forget" element={<ForgetPassword />} /> */}
          <Route path="/" element={<HomePage />} />
          {/* <Route path="/1" element={<HomePage2 />} /> */}
          <Route path="/about" element={<About />} />
          <Route path="/product2" element={<ProductPage2 />} />
          <Route path="/product1" element={<ProductPage1 />} />
          <Route path="/product3" element={<ProductPage3 />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogpage" element={<Singleblog />} />
          <Route path="/blogpage1" element={<SecondBlog />} />
          <Route path="/blogpage2" element={<ThirdBlog />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/shop" element={<Shopnow />} />


        </Routes>
      </Router>
    </div>
    </ChakraProvider>
  );
}

export default App;
