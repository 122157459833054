import * as React from "react";
import "./Navbar.css";


function Navbar() {

    const [isOpen, setIsOpen] = React.useState(false);

    React.useEffect(() => {
        const link = document.createElement("link");
        link.href =
            "https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap";
        link.rel = "stylesheet";
        document.head.appendChild(link);

        const handleScroll = () => {
            const navbar = document.querySelector(".header-container");
            if (window.scrollY > 300) {
                navbar.classList.add("sticky");
            } else {
                navbar.classList.remove("sticky");
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <div className="header-navbar">
                <div className="header-container">
                    <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/92da32934a0bbd97a0c54082fb4dd41319b7876fa47787b3e780c4c6b2dfa6da?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                        alt="company logo"
                        className="logo"
                    />
                    <nav className={`nav ${isOpen ? "open" : ""}`}>
                        <a href="/" className="nav-link">
                            Home
                        </a>
                        <a href="/about" className="nav-link">
                            About Us
                        </a>
                        <a href="/shop" className="nav-link">
                            Products
                        </a>
                        <a href="/blogs" className="nav-link">
                            Blogs
                        </a>
                        <a href="/contactus" className="nav-link">
                            Contact Us
                        </a>
                    </nav>
                    <div className="hamburger" onClick={toggleMenu}>
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                    </div>
                    <div className="nav-actions">
                        {/* <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/8d2b9687b3e5d20a6d281b536b55cc9cff693862303a372f196299db8db8a6b1?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                            alt="another company logo"
                            className="nav-logo"
                        />
                        <button className="login-button" onClick={() => window.location.href = '/login'}>
                            <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/fb4261b19dd0b049dffba464ed35a96b361345894a19b0e29a463b286d7de038?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&"
                                alt="login icon"
                                className="login-icon"
                            />
                            <span className="login-text">Log In</span>
                        </button> */}
                    </div>
                    </div>
                </div>
            {/* </div> */}
        </>
    );
}

export default Navbar;
