// import * as React from "react";
import "./Shopnow.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import {
    Card,
    Stack,
    CardBody,
    CardFooter,
    Image,
    Button,
    Text,
    Heading,
} from '@chakra-ui/react';
import shop_product_image1 from "../../assets/image/shop_product_image1.png";
import shop_product_image2 from "../../assets/image/product_main1.png";
import shop_product_image3 from "../../assets/image/shop_product_image3.png";


function Shopnow() {

    return (
        <>
        <Navbar />
        <div style={{display: 'flex',
            padding: '10px 50px 10px 50px', 
            gap: '20px'}}>
        <Card
            direction={{ base: 'column', sm: 'column' }}
            overflow='hidden'
            variant='outline'
            >
            <Image
                objectFit='cover'
                maxW={{ base: '100%'}}
                src={shop_product_image1}
                alt='Caffe Latte'
            />
            <Stack>
                <CardBody>
                <Heading size='md' textAlign='left'>Volta 40</Heading>

                <Text py='5' textAlign='left' maxH='100px'>
                    Stay connected with the Volta 40. Seamless Wi-Fi, even during power cuts, 
                    for uninterrupted work or entertainment.
                </Text>
                </CardBody>

                <CardFooter>
                <Button variant='solid' bg='#3AB54B' color='white' onClick={() => window.location.href = '/product1'}>
                    Shop now
                </Button>
                </CardFooter>
            </Stack>
            </Card>



            <Card
            direction={{ base: 'column', sm: 'column' }}
            overflow='hidden'
            variant='outline'
            >
            <Image
                objectFit='cover'
                maxW={{ base: '100%'}}
                src={shop_product_image2}
                alt='Caffe Latte'
            />
            <Stack>
                <CardBody>
                <Heading size='md' textAlign='left'>Volta 500</Heading>

                <Text py='5' textAlign='left' maxH='100px'>
                    Enjoy uninterrupted entertainment by keeping your Volta 500 portable power station tucked 
                    away in a cupboard next to your TV. 
                </Text>
                </CardBody>

                <CardFooter>
                <Button variant='solid' bg='#3AB54B' color='white'  onClick={() => window.location.href = '/product2'}
                >
                    Shop now
                </Button>
                </CardFooter>
            </Stack>
            </Card>
            <Card
            direction={{ base: 'column', sm: 'column' }}
            overflow='hidden'
            variant='outline'
            >
            <Image
                objectFit='cover'
                maxW={{ base: '100%'}}
                src={shop_product_image3}
                alt='Caffe Latte'
            />
            <Stack>
                <CardBody>
                <Heading size='md' textAlign='left'>Volta 700X</Heading>
                <Text py='5' textAlign='left' maxH='100px'>
                    The Volta 700X boasts a rugged body and a handle for easy transport. 
                    At 6.5kg, it's light enough to take anywhere for work or play.
                </Text>
                </CardBody>
                <CardFooter>
                <Button variant='solid' bg='#3AB54B' color='white' onClick={() => window.location.href = '/product3'}>
                    Shop now
                </Button>
                </CardFooter>
            </Stack>
            </Card>
            </div>
        <Footer />
        </>
    );
}

export default Shopnow;