import * as React from "react";
import "./About.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
// import what_image from "../../assets/image/what_image.svg";
import what_image from "../../assets/optimized_images/what_image.png";
// import about_master from "../../assets/image/about_master_image.svg";
import about_master from "../../assets/optimized_images/about_master_image.png";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";


const solutionData = [
  {
    id: 1,
    title: 'Solar Solutions',
    content: "We provide a diverse selection of premium solar panels, inverters, batteries, and accessories to meet your energy needs. Our products are designed for efficiency and long-lasting performance, ensuring you get the most out of your investment. Whether you're powering a home or a business, we have the solutions to help you harness the sun's energy."
  },
  {
    id: 2,
    title: 'Sustainable & Affordable',
    content: "We customize solar solutions to fit the unique needs and budget of every customer, ensuring an optimal match for your energy goals. Our expert team works closely with you to design a system that maximizes efficiency and cost-effectiveness. By personalizing our approach, we deliver tailored results that align perfectly with your requirements."
  },
  {
    id: 3,
    title: 'Custom Fit Solutions',
    content: "We empower communities and businesses by providing clean, reliable, and cost-effective solar energy solutions. Our innovative systems are designed to deliver sustainable power while reducing energy costs. By fostering a greener future, we help you achieve energy independence and contribute to environmental preservation."
  },
  {
    id: 4,
    title: 'Maintenance and Support',
    content: "We offer regular maintenance services to keep your solar system running at peak efficiency. Our comprehensive checks and timely interventions ensure optimal performance and longevity of your equipment. By addressing potential issues before they arise, we help you maximize energy production and avoid costly repairs."
  },
  {
    id: 5,
    title: 'Consultancy and Design',
    content: "Our expert consultancy provides tailored advice for designing solar energy systems that perfectly match your needs. We conduct a detailed analysis of your energy consumption to create customized designs that maximize efficiency and savings. With our personalized approach, you receive a solution that is both effective and aligned with your specific energy requirements."
  },
  {
    id: 6,
    title: 'Here for You',
    content: "We’re here to assist with any questions or concerns you may have regarding our solar energy solutions. Our dedicated team is available to provide clear, thorough answers and support throughout your journey. Whether you need technical information or guidance on next steps, we're committed to ensuring you have all the assistance you need."
  }
];

function SolutionButton({ title, onClick, isActive }) {
  return (
    <button
      className={`solution-button ${isActive ? 'active' : ''}`}
      onClick={onClick}
    >
      {title}
    </button>
  );
}

function SolutionContent({ title, content }) {
  return (
    <div className="solution-content">
      <h3 className="content-title">{title}</h3>
      <p className="content-description">{content}</p>
    </div>
  );
}


const faqData = [
  {
    question: "What is solar energy?",
    answer: "Solar energy is renewable energy derived from the sun's radiation. It is harnessed using solar panels to generate electricity or heat for various applications."
  },
  {
    question: "How does solar energy work?",
    answer: "Solar panels capture sunlight and convert it into electricity through photovoltaic cells. This electricity can be used immediately, stored in batteries, or fed back into the power grid."
  },
  {
    question: "How does solar energy reduce carbon emissions?",
    answer: "Solar energy produces clean, renewable electricity without burning fossil fuels. By replacing traditional energy sources, it significantly reduces greenhouse gas emissions and helps combat climate change."
  }
];

const FAQItem = ({ question, answer, isLast}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className="faq-item-master">
      <div className="faq-item">
        <div className="faq-question" onClick={() => setIsOpen(!isOpen)}>
          <span>{question}</span>
        {isOpen ? <IoIosArrowUp className="toggle-icon" /> : <IoIosArrowDown className="toggle-icon" />}
        </div>
        {isOpen && <div className="faq-answer">{answer}</div>}
        {!isLast && <div className="divider" />}
      </div>
    </div>
  );
};



function About() {

    React.useEffect(() => {
        const link = document.createElement("link");
        link.href =
            "https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap";
        link.rel = "stylesheet";
        document.head.appendChild(link);
        }, []);

        const features = [
          {
            iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/23c0042c123f37ccc33cd60f046801ebfd5f2fe2bfaaaa204a40d448234d3a64?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&",
            title: "Quality Assurance",
            description: "Rigorous quality checks ensure reliable and long-lasting products."
          },
          {
            iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/4c2df18a8d4edc9bf5299f000b8b4c7139793b0fd9e61dc316765edd299b98e3?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&",
            title: "24 Hours Customer Support",
            description: "Our Customer Support is always in touch with you! You can contact us anytime and anywhere!"
          },
          {
            iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/fe09e236bc785cab347c5e99652ab0ee393194382565d365865fe0fb4a449cf2?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&",
            title: "Customer Satisfaction",
            description: "Dedicated to ensuring customer satisfaction at every step."
          }
        ];

        const [searchTerm, setSearchTerm] = React.useState('');

        const filteredFAQs = faqData.filter(faq =>
          faq.question.toLowerCase().includes(searchTerm.toLowerCase())
        );

    const [activeContent, setActiveContent] = React.useState(1);

    const firstSet = solutionData.slice(0, 3);
    const secondSet = solutionData.slice(3);

    const activeSolution = solutionData.find(sol => sol.id === activeContent);

    return(
        <>
        <Navbar/>

        <main className="energy-mission-page">
            <img
            loading="lazy"
            src={about_master}
            className="background-image"
            alt="Energy mission background"
            />
            <section className="mission-section">
            <h2 className="mission-title">Our Mission</h2>
            <div className="mission-underline" />
            <p className="mission-description">
                At Matoto Energy Group, our mission is to empower communities by
                providing access to clean, reliable, and sustainable energy
                solutions. We believe in driving positive change through
                environmentally-friendly technologies that enhance lives and
                contribute to a brighter future. With a focus on innovation and
                excellence, we aim to revolutionize the energy sector
            </p>
            <button className="cta-button"
              onClick={() => window.location.href = '/product'}
            >
              Shop to Products</button>
            </section>
            <section className="info-section-about">
            <h2 className="info-title-about">Why is it Necessary?</h2>
            <p className="info-description-about">
                Clean energy solutions are imperative for a sustainable future,
                curbing emissions, diversifying energy sources, and fostering
                economic growth. By reducing reliance on fossil fuels, we mitigate
                environmental impact, improve air quality, and create healthier
                living environments. Investing in clean energy not only cuts
                long-term costs but also generates new employment opportunities in
                the renewable energy sector. Embracing clean energy is pivotal for a
                greener, healthier, and more prosperous world for generations to
                come.
            </p>
            </section>
        </main>

      <div className="why-choose-us-container">
      <div className="features-grid">
            <div className="section-title">Why Choose Us?</div>
            <div className="title-underline"></div>
            <div className="features-container">
                {features.map((feature, index) => (
                  <div key={index} className="feature-card">
                    <img src={feature.iconSrc} alt="" className="feature-icon" />
                    <h3 className="feature-title">{feature.title}</h3>
                    <p className="feature-description">{feature.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>

        <div className="why-do-master">
          <div className="heading-master">
            <h3 className="what-we-do-heading">What We DO?</h3>
            <p className="heading-descriptions">At Matoto Energy Group, we handle every step of your renewable energy journey, from consultation to installation and maintenance, ensuring efficient and reliable solar energy for your peace of mind. </p>
          </div>
            <div className="bullets-image-master">

              
              <section className="solar-solutions">
              <div className="content-wrapper">
                <div className="image-column">
                  <img
                    // src="https://cdn.builder.io/api/v1/image/assets/TEMP/5c27145f22b85b1acbfd6c2afafb2e999617da5ec8bad584e12a03f0c4e4e620?placeholderIfAbsent=true&apiKey=e43cc0bde9a34df4ab3bb8010053cc61"
                    src={what_image}
                    alt="Solar panel installation"
                    className="solar-image"
                  />
                </div>
                <div className="info-column">
                  {/* <div className="button-group">
                    {solutionData.map((solution) => (
                      <SolutionButton
                        key={solution.id}
                        title={solution.title}
                        onClick={() => setActiveContent(solution.id)}
                        isActive={activeContent === solution.id}
                      />
                    ))}
                  </div> */}
                  <div>
                    <div className="button-group">
                      <div className="button-set">
                        {firstSet.map((solution) => (
                          <SolutionButton
                            key={solution.id}
                            title={solution.title}
                            onClick={() => setActiveContent(solution.id)}
                            isActive={activeContent === solution.id}
                          />
                        ))}
                      </div>
                      <div className="button-set">
                        {secondSet.map((solution) => (
                          <SolutionButton
                            key={solution.id}
                            title={solution.title}
                            onClick={() => setActiveContent(solution.id)}
                            isActive={activeContent === solution.id}
                          />
                        ))}
                      </div>
                    </div>
                    {activeSolution && (
                      <SolutionContent
                        title={activeSolution.title}
                        content={activeSolution.content}
                      />
                    )}
                  </div>
                  {/* {activeContent && (
                    <SolutionContent
                      title={solutionData.find((s) => s.id === activeContent).title}
                      content={solutionData.find((s) => s.id === activeContent).content}
                    />
                  )} */}
                </div>
              </div>
            </section>
            </div>
        </div>

        <div className="faq-section">
      <h1 className="section-title">Frequently Asked Questions</h1>
      <p className="section-subtitle">Have Questions? We're Here to help.</p>
      {/* <div className="search-container">
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/7a9c92be33e335cba3e237970b49b67fe16c46841e36fe67a4f16d13c97741d7?apiKey=e43cc0bde9a34df4ab3bb8010053cc61&" className="search-icon" alt="" />
        <input
          type="text"
          className="search-input"
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div> */}
      {filteredFAQs.map((faq, index) => (
        <FAQItem 
          key={index} 
          question={faq.question} 
          answer={faq.answer} 
          isLast={index === faqData.length - 1}
        />
      ))}
    </div>

        <section className="footer-contact-info">
          <div className="contact-info-wrapper">
            <div className="contact-address">
              <h3 className="address-title">
                Get in touch with our team of Solar Panel services today!
              </h3>
              <p className="address-description">
                If you want to learn more about to Solar Panel & skylight, get
                in touch with our expert and get your queries addressed today!
              </p>
              <button className="address-button"
                onClick={() => window.location.href = '/contactus'}
              >
                Talk To An Expert</button>
            </div>
          </div>
        </section>
      <Footer />
      </>
    );
}
export default About;